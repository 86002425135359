<template>
  <div class="space-y-4">
    <div class="space-y-10">
      <article v-if="canCreateCashFloatRecord">
        <h3 class="subheader">
          Quick Links
        </h3>
        <br />
        <section
          class="relative z-0  rounded-md grid sm:grid-cols-1 md:grid-cols-4"
        >
          <asom-button
            variant="primary"
            outline
            rounded="none"
            @click="moveCashBag"
            icon="arrow-left"
            text="Move Cash Bag"
          >
          </asom-button>
          <!-- <asom-button
            variant="primary"
            outline
            rounded="none"
            @click="createShiftEndSummary"
            icon="mail"
            text="Shift End Summary"
          >
          </asom-button> -->
        </section>
      </article>

      <template v-if="!isLoading">
        <div class="flex flex-col sm:w-full lg:w-2/3">
          <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div
              class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
            >
              <div
                class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
              >
                <table class="min-w-full divide-y divide-gray-200">
                  <caption class="hidden"></caption>
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider"
                      >
                        Bag Status
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider"
                      >
                        Closed
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider"
                      >
                        Opened
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="bg-white">
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 align-top"
                      >
                        Certis Bags (Manual Change)
                      </td>
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top"
                      >
                        <div v-if="closedBags" class="flex flex-col space-y-1">
                          <div
                            v-for="bag in closedBags"
                            :key="bag.bagId"
                            class="flex flex-row items-center space-x-1 align-center"
                          >
                            <div
                              class="cursor-pointer underline text-button"
                              @click="viewBag(bag)"
                            >
                              {{ bag.bagNo }}
                            </div>
                            <asom-badge v-if="isBagMovedOut(bag)"
                              >In transit</asom-badge
                            >
                            <button
                              v-if="canOpenBag(bag) && canCreateCashFloatRecord"
                              @click="showConfirmation(false, bag.bagId)"
                            >
                              <asom-icon icon="lock" class="text-button" />
                            </button>
                          </div>
                        </div>
                      </td>
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top"
                      >
                        <div v-if="openBags" class="flex flex-col">
                          <div
                            v-for="bag in openBags"
                            :key="bag.bagId"
                            class="flex flex-row items-center space-x-4 space-y-2 align-center"
                          >
                            <div
                              class="cursor-pointer underline text-button"
                              @click="viewBag(bag)"
                            >
                              {{ bag.bagNo }}
                            </div>
                            <asom-button
                              v-if="canCreateCashFloatRecord"
                              size="xs"
                              variant="primary"
                              outline
                              text="Used"
                              @click="showConfirmation(true, bag.bagId)"
                              >
                            </asom-button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot class="bg-gray-50">
                    <tr v-if="openBagsTotalAmount || closedBagsTotalAmount">
                      <td
                        class="px-6 py-3 text-left font-large text-black text-sm font-semibold"
                      >
                        Amount of Cash
                      </td>
                      <td
                        class="px-6 py-3 text-left font-large text-black text-sm font-semibold"
                      >
                        ${{ closedBagsTotalAmount }}
                      </td>
                      <td
                        class="px-6 py-3 text-left font-large text-black text-sm font-semibold"
                      >
                        ${{ openBagsTotalAmount }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- filter begin-->
        <asom-card title="Manual Change Transaction Log">
          <div class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
            <asom-form-field
              label="Date Created"
              required
              :state="inputStates('filters.dateRange')"
            >
              <asom-input-date-range
                v-model="filters.dateRange"
                datepicker-placement="bottom"
                :state="inputStates('filters.dateRange')"
              />
            </asom-form-field>
          </div>
          <template #footer>
            <asom-button
              text="Reset Filters"
              variant="secondary"
              @click="resetFilters"
            />
            <asom-button text="Apply" @click="getPageData" />
          </template>
        </asom-card>
        <!-- filter end-->
        <asom-card title="Manual Change Transaction Log">
          <asom-client-table
            :columns="[
              'transactionNo',
              'createdBy',
              'dateCreated',
              'certisBagNo',
              'amount',
              'transactionType',
              'action',
            ]"
            :data="getTableData"
            :sortableColumns="[
              'transactionNo',
              'createdBy',
              'dateCreated',
              'certisBagNo',
              'amount',
              'transactionType',
            ]"
            :searchableDateColumns="['dateCreated']"
            :searchableCurrencyColumns="['amount']"
          >
            <template v-slot:amount="scopedSlots">
              <span>{{ formatCurrency(scopedSlots.data) }}</span>
            </template>
            <template v-slot:header_createdBy>Staff ID</template>
            <template v-slot:header_certisBagNo
              >Certis Bag (Manual Change)</template
            >
            <template v-slot:header_transactionType>Transaction Type</template>
            <template v-slot:transactionType="scopedSlots">
              <span>{{
                scopedSlots.data == "Discard" ? "Used" : scopedSlots.data
              }}</span>
            </template>
            <template v-slot:transactionNo="scopedSlots">
              <div class="space-x-2 flex flex-row items-center">
                <div class="flex flex-row pr-4">
                  <div class="flex pl-2">
                    <span
                      :class="[scopedSlots.rowData.isVoided && 'line-through']"
                    >
                      {{ scopedSlots.data }}
                    </span>
                  </div>
                </div>
                <asom-tooltip
                  v-if="get(scopedSlots.rowData, 'addedToCDF')"
                  tooltip-text="Bagged in Cash Declaration"
                >
                  <template #toggleText>
                    <asom-badge variant="primary" class="p-1">
                      <asom-icon icon="shopping-bag" size="sm" />
                    </asom-badge>
                  </template>
                </asom-tooltip>
                <asom-tooltip
                  v-if="get(scopedSlots.rowData, 'addedToShiftEnd')"
                  tooltip-text="Added to shift End"
                >
                  <template #toggleText>
                    <asom-badge variant="success" class="p-1">
                      <asom-icon icon="mail" size="sm" />
                    </asom-badge>
                  </template>
                </asom-tooltip>
              </div>
            </template>
            <template v-slot:header_dateCreated>Date</template>
            <template v-slot:dateCreated="scopedSlots">
              <span>{{ displayUtcDate(scopedSlots.data) }}</span>
            </template>
            <template v-slot:action="scopedSlots">
              <div
                class="flex flex-row"
                v-if="isEditableTransaction(scopedSlots.rowData) && canCreateCashFloatRecord"
              >
                <div class="flex">
                  <asom-button
                    size="sm"
                    outline
                    text="Cancel Entry"
                    variant="error"
                    @click="cancelClicked(scopedSlots.rowData)"
                  />
                </div>
              </div>
            </template>
          </asom-client-table>
        </asom-card>

        <activity-log-list
          title="Cash Float e-Log Transaction Activity Log"
          :data="activityLogs"
        />
      </template>
    </div>

    <asom-modal
      title="Failed to complete request"
      v-model="showError"
      size="md"
      dismissible
    >
      <asom-alert class="my-4" variant="error" :error-message="error" />
    </asom-modal>
    <asom-modal
      title="Change Bag Status"
      v-model="showBagStatusConfirmation"
      :dismissible="false"
    >
      <p class="pt-4">{{ confirmationMessage }}</p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
            :loading="isSubmitting"
            :disabled="isSubmitting"
            @click="onConfirmModal(bagStatusChange.isOpened, bagStatusChange.bagId)"
            text="OK"
          />
        </div>
        <div>
          <asom-button
            @click="cancelConfirmation"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
    <asom-modal
      title="Cancel Confirmation"
      v-model="showCancelModal"
      :dismissible="false"
    >
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
            @click="onSubmitCancel"
            :disabled="isSubmittingCancel"
            :loading="isSubmittingCancel"
            text="OK"
          />
        </div>
        <div>
          <asom-button
            @click="toggleCancelModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </div>
</template>

<script>
import inputStates from "@/mixins/inputStates";
import { mapGetters } from "vuex";
import get from "lodash.get";
import filter from "lodash.filter";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import {
  getPSCCashFloatMainPageData,
  openCertisBag,
  discardCertisBag,
  editCertisBagTransaction,
} from "../../../../services/cashManagement.service";
import ActivityLogList from "../../_ActivityLogList.vue";
import { psc } from "../../../../constants/APIEnums/cashManagementEnums";
import { formatCurrency } from "../../../../helpers/numbers";
import moment from "moment";
import {toMoment} from "@/helpers/dateTimeHelpers";

export default {
  name: "CashFloatMainPage",
  mixins: [inputStates],
  components: {
    ActivityLogList,
  },
  data() {
    return {
      filters: {
        dateRange: {
          start: moment().subtract(1, "months").toDate(),
          end: moment().toDate(),
        }
      },
      transactionLogs: [],
      activityLogs: [],
      isLoading: false,
      closedBags: null,
      openBags: null,
      error: null,
      showError: false,
      showBagStatusConfirmation: false,
      bagStatusChange: {
        isOpened: false,
        bagId: null,
      },
      closedBagsTotalAmount: 0,
      openBagsTotalAmount: 0,
      showCancelModal: false,
      isSubmittingCancel: false,
      isSubmitting: false,
      selectedTransaction: null,
    };
  },
  computed: {
    ...mapGetters({
      pscId: "cashManagement/stationCashAccounts/pscId",
      rosterPlanDate: "currentRoster/currentRosterPlanDate",
      rosterDws: "currentRoster/currentRosterShift",
      shift: "currentRoster/currentRosterShift",
      canCreateCashFloatRecord: "auth/canCreateCashFloatRecord",
    }),
    confirmationMessage() {
      return this.bagStatusChange.isOpened
        ? "Please confirm to use cash bag"
        : "Please confirm to open cash bag";
    },
    queryParams() {
      let params = {};
      if (
        this.filters.dateRange &&
        this.filters.dateRange.start &&
        this.filters.dateRange.end
      ) {
        params["searchDateFrom"] = this.parseDateTimeUTC(
          this.filters.dateRange.start,
          true
        );
        params["searchDateTo"] = this.parseDateTimeUTC(
          this.filters.dateRange.end,
          false
        );
      }
      return params;
    },
    getTableData() {
      return this.transactionLogs.map((row) => {
        if (row.isVoided) row.deleted = true;
        return row;
      });
    },
  },
  mounted() {
    this.getPageData();
  },
  watch: {
    pscId(newId, oldId) {
      if (newId !== oldId) this.getPageData();
    },
  },
  methods: {
    get,
    formatCurrency,
    resetFilters() {
      this.filters.dateRange = null;
    },
    isEditableTransaction(transaction) {
      return (
        !transaction.isVoided &&
        [4, 5].includes(get(transaction, "transactionTypeEnum"))
      );
    },
    isBagMovedOut({ lastTransactionTypeEnum }) {
      return (
        lastTransactionTypeEnum ==
        psc.cashFloatELog.CERTIS_BAG_TRANSACTION_TYPE.MOVE_OUT.VALUE
      );
    },
    canOpenBag({ statusEnum, lastTransactionEnum }) {
      return (
        lastTransactionEnum ==
          psc.cashFloatELog.CERTIS_BAG_TRANSACTION_TYPE.MOVE_IN &&
        statusEnum == psc.cashFloatELog.CERTIS_BAG_STATUS.SEALED
      );
    },
    displayUtcDate,
    onCloseModal() {
      this.showError = false;
      this.error = null;
    },
    showConfirmation(isOpened, bagId) {
      this.bagStatusChange = {
        isOpened: isOpened,
        bagId: bagId,
      };
      this.showBagStatusConfirmation = true;
    },
    cancelConfirmation() {
      this.bagStatusChange = null;
      this.showBagStatusConfirmation = false;
    },
    async onConfirmModal(isOpened, bagId) {
      this.isSubmitting = true;
      if (isOpened) {
        await this.onDiscardCertisBag(bagId);
      } else {
        await this.onOpenCertisBag(bagId);
      }
      this.bagStatusChange = null;
      this.isSubmitting = false;
      this.showBagStatusConfirmation = false;
    },
    viewBag(bag) {
      this.$router.push({
        name: "Cash Float e-Log View Cash Bag",
        params: bag,
      });
    },
    cancelClicked(transaction) {
      this.selectedTransaction = transaction;
      this.toggleCancelModal(true);
    },
    toggleCancelModal(value) {
      this.showCancelModal = value;
    },
    async onSubmitCancel() {
      this.isSubmittingCancel = true;
      const result = await editCertisBagTransaction({
        transactionId: get(this.selectedTransaction, "transactionId", ""),
        isVoid: true,
        remarks: "Void",
      });
      if (result.success) {
        this.selectedTransaction = null;
        this.getPageData();
      } else {
        this.showError = true;
        this.error = result.payload;
      }
      this.isSubmittingCancel = false;
      this.toggleCancelModal(false);
    },
    createShiftEndSummary() {
      let total = 0;
      let openTransactions = filter(this.transactionLogs, {
        addedToShiftEnd: false,
        transactionTypeEnum:
          psc.cashFloatELog.CERTIS_BAG_TRANSACTION_TYPE.OPEN.VALUE,
      });
      openTransactions.forEach((log) => {
        total += log.amount;
      });
      let logs = {
        totalAmount: total,
      };
      this.$router.push({
        name: "Cash Float e-Log Shift End Summary",
        query: logs,
      });
    },
    moveCashBag() {
      this.$router.push({ name: "Cash Float e-Log Move Cash Bag" });
    },
    async getPageData() {
      this.isLoading = true;
      await this.$store.dispatch("cashManagement/loadStationCashAccounts");
      const resp = await getPSCCashFloatMainPageData({
        pSCId: this.pscId,
        ...this.queryParams,
      });
      if (resp.success) {
        this.transactionLogs = get(resp.payload.data, "transactions", []);
        this.activityLogs = get(resp.payload.data, "activityLogs", []);
        let certisBags = get(resp.payload.data, "certisBags", []);
        this.closedBagsTotalAmount = 0;
        this.openBagsTotalAmount = 0;
        this.closedBags = certisBags.filter((bag) => {
          if (bag.status == "Sealed") {
            this.closedBagsTotalAmount += bag.amount;
            return bag;
          }
        });
        this.openBags = certisBags.filter((bag) => {
          if (bag.status == "Opened") {
            this.openBagsTotalAmount += bag.amount;
            return bag;
          }
        });
        this.isLoading = false;
      } else return false;
    },
    async onOpenCertisBag(bagId) {
      const result = await openCertisBag({
        accountId: this.pscId,
        bagId: bagId,
        rosterPlanDate: this.rosterPlanDate,
        rosterDWS: this.rosterDws,
      });
      if (result.success) {
        await this.getPageData();
      } else {
        this.showError = true;
        this.error = result.payload;
      }
    },
    async onDiscardCertisBag(bagId) {
      const result = await discardCertisBag({
        accountId: this.pscId,
        bagId: bagId,
        rosterPlanDate: this.rosterPlanDate,
        rosterDWS: this.rosterDws,
      });
      if (result.success) {
        await this.getPageData();
      } else {
        this.showError = true;
        this.error = result.payload;
      }
    },
    parseDateTimeUTC(datetimeValue, isStartDate) {
      var d = toMoment(datetimeValue);
      if (d.isValid()) {
        var _d = isStartDate ? d.startOf("day") : d.endOf("day");
        const UTC_d = moment.utc(_d);
        return UTC_d.format("YYYY-MM-DD") + "T" + UTC_d.format("HH:mm:ss");
      }
      return "";
    },
  },
};
</script>
